<template>
    <v-container fluid>
        <div v-if="!studentListPage">
            <v-row justify='space-between'>
                <v-col cols="8">
                    <h1>Mango CMU</h1>
                </v-col>
                
                <v-col v-if=" currentSemester === courseData.semester || yearlySemester === courseData.semester || nextSemester === courseData.semester || courseData.semester === '466'">
                    <v-row justify='end' align-end class="mr-1">
                        <v-spacer></v-spacer>
                        <template>
                            <div  @click="clickInfoToggle" v-if="currentSemester === courseData.semester || courseData.semester === yearlySemester || courseData.semester === '466'">
                                <v-icon size="28px" style="margin-top: 1.7rem; margin-right: 1rem;">
                                mdi-help-circle
                                </v-icon>
                            </div>
                            <v-dialog
                                v-model="dialog"
                                scrollable
                                max-width="400px"
                                v-if="currentSemester === courseData.semester || yearlySemester === courseData.semester || courseData.semester === '466'"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="mr-2 mt-5"
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                    SYNC WITH REG
                                    </v-btn>

                                </template>
                                <v-card>
                                    <v-card-title>Select Section</v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text style="height: 300px;">
                                        <v-checkbox
                                            class="mt-0"
                                            v-model="selectAll"
                                            label="เลือกทั้งหมด"
                                            value="All"
                                        ></v-checkbox>
                                        <v-expansion-panels flat v-model="serviceList" multiple >
                                            <v-expansion-panel
                                                v-for="(detail, i , idx  ) in lmsListEachService"
                                                :key="parseInt(idx)"
                                            >
                                                <!-- {{ detail.module }} -->
                                                <v-checkbox
                                                    class="mt-0"
                                                    v-model="serviceList"
                                                    :label="detail[0].service=='moodle'?'CMU ONLINE':'MANGO CMU'"
                                                    :value="idx"
                                                    style=""
                                                    v-if="detail.length > 0"
                                                ></v-checkbox>
                                                <v-expansion-panel-content >
                                                    <template v-for="(item) in detail" >
                                                        <v-checkbox
                                                            v-model="sectionSelected"
                                                            class="mt-0"
                                                            :label="`${item.title}  (${sectionAfterTitleName(item.sections)})`"
                                                            :value="item._id"
                                                            :key="`checkout-${idx}-${item._id}`"
                                                            >
                                                        </v-checkbox>
                                                        <div class="break" :key="`break-${idx}-${item._id}`"></div>
                                                    </template>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="dialog = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="onHandleAutoSync()"
                                    >
                                        Confirm
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                        <div class="mt-5" >
                            <create-lms-modal :courseData="courseData" :mangoGrant="showMenu.mango" @modalSubmit="onModalSubmit" :openModal="openCreateModal" @modalClose="openCreateModal = false"/>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-if="lmsListEachService.canvas.length > 0 || lmsListEachService.moodle.length > 0 || lmsListEachService.mango.length > 0">
                <v-col>
                    <v-expansion-panels v-model="panel" multiple>
                        <v-expansion-panel v-if="lmsListEachService.canvas.length > 0">
                            <v-expansion-panel-header class="canvas-outlined">
                                <v-img 
                                    alt="Canvas Logo"
                                    src="../assets/images/canvas-logo-crop.png"
                                    max-height="100"
                                    max-width="100"
                                >
                                </v-img>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col v-if="lmsListEachService.canvas.length > 0">
                                        <div v-for="(lms, i) in lmsListEachService.canvas" :key="i">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-icon>mdi-circle-small</v-icon>
                                                        {{`${lms.title} (${sectionAfterTitleName(lms.sections)})`}}
                                                    </v-list-item-title>
                                                    <v-list-item-title v-if="lms.jobStatus === 'error'">
                                                        <v-icon color="red">mdi-circle-small</v-icon>
                                                        <strong class="red--text text--lighten-1">{{`${lms.errorCode.message.code}`}}</strong>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                <v-switch
                                                    v-model="lms.syncEnable"
                                                    color="success"
                                                    inset
                                                ></v-switch>
                                                <v-list-item-icon>
                                        
                                                    <v-btn class="mr-1 white--text" width="150px" :color="jobStatusBtn(lms.jobStatus)" @click="showDetailcreating(lms)">
                                                        {{jobStatus(lms.jobStatus)}}
                                                        <i class="fas fa-spinner fa-pulse  fa-spin fa-fw" v-if="lms.jobStatus !== 'created' && lms.jobStatus !== 'error' && lms.jobStatus !== 'synced' && lms.jobStatus !== 'syncing' "></i>
                                                    </v-btn>
                                                    <v-menu
                                                        bottom
                                                        left
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >
                                                            <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item @click="clickDelete(lms)">
                                                                <v-list-item-title><v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item @click="clickEdit(lms)">
                                                                <v-list-item-title><v-icon>mdi-square-edit-outline</v-icon> Edit</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </v-list-item-icon>
                                            </v-list-item>
                                            <v-divider v-if="i+1 !== lmsListEachService.canvas.length"></v-divider>
                                        </div>
                                    </v-col>
                                    <v-col v-else class="text-center">
                                        <h2>Not found</h2>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="lmsListEachService.moodle.length > 0">
                            <v-expansion-panel-header class="violet-outlined">
                                <v-img 
                                    alt="moodle Logo"
                                    src="../assets/images/moodle-logo-crop.png"
                                    max-height="100"
                                    max-width="100"
                                >
                                    
                                </v-img>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col v-if="lmsListEachService.moodle.length > 0">
                                        <div v-for="(lms, i) in lmsListEachService.moodle" :key="i">
                                            <v-list-item>
                                            
                                                <v-list-item-content>
                                                        <v-tooltip bottom close-delay="2000" color="#333">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <v-list-item-title 
                                                                    v-bind="attrs"
                                                                    v-on="on">
                                                                    <v-icon>mdi-circle-small</v-icon>
                                                                    {{`${lms.title} (${sectionAfterTitleName(lms.sections)})`}}
                                                                </v-list-item-title>
                                                                <v-list-item-title v-if="lms.jobStatus === 'error'">
                                                                    <v-icon color="red">mdi-circle-small</v-icon>
                                                                    <strong class="red--text text--lighten-1">{{` ${hasObjectError(lms)}`}}</strong>
                                                                </v-list-item-title>
                                                            </template>
                                                            <span >{{sectionAfterTitleName(lms.sections)}}</span>
                                                        </v-tooltip>
                                                </v-list-item-content>
                                                
                                                <v-list-item-icon>
                                                    <v-row align="center"
                                                        justify="center">
                                                        <v-col class="mt-3">
                                                            <template  v-if="currentSemester === courseData.semester || courseData.semester === '466' || courseData.semester === yearlySemester">
                                                                <v-row >
                                                                    <v-col >
                                                                        <v-row >
                                                                            <div  style="font-size: 10px;">Auto Add/Drop</div>
                                                                        </v-row>
                                                                        <v-row   style="font-size: 10px; margin-left: 0rem;">
                                                                            <div class="bi-state-toggle">
                                                                                
                                                                                <button :class="!lms.syncEnable? 'bi-state-toggle-button-no active' : 'bi-state-toggle-button-no'" @click="clickSync(lms.syncEnable, lms.courseid , lms._id )"  id="toggle-button3">
                                                                                </button>
                                                                        
                                                                                <button :class="lms.syncEnable? 'bi-state-toggle-button active' : 'bi-state-toggle-button'" @click="clickSync(lms.syncEnable, lms.courseid , lms._id )" id="toggle-button1">
                                                                                </button>
                                                                            </div>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                        </v-col>
                                                        <v-col>
                                                            <v-btn class="mr-1 white--text" width="150px" :color="jobStatusBtn(lms.jobStatus)" @click="showDetailcreating(lms)">
                                                                {{jobStatus(lms.jobStatus)}}
                                                                <i class="fas fa-spinner fa-pulse  fa-spin fa-fw" v-if="lms.jobStatus !== 'created' && lms.jobStatus !== 'error' && lms.jobStatus !== 'synced' && lms.jobStatus !== 'syncing' "></i>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="2">
                                                            <v-menu
                                                                bottom
                                                                left
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                    icon
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    >
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list>
                                                                    <v-list-item @click="clickEdit(lms)">
                                                                        <v-list-item-title><v-icon>mdi-square-edit-outline</v-icon> Edit</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item @click="clickDelete(lms)">
                                                                        <v-list-item-title><v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                            
                                                </v-list-item-icon>
                                            </v-list-item>
                                            <v-divider v-if="i+1 !== lmsListEachService.moodle.length"></v-divider>
                                        </div>
                                    </v-col>
                                    <v-col v-else class="text-center">
                                        <h2>Not found</h2>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="lmsListEachService.mango.length > 0">
                            <v-expansion-panel-header class="mango-outlined">
                                <v-img 
                                    alt="Mango Logo"
                                    src="../assets/images/mango-logo-crop.png"
                                    max-height="100"
                                    max-width="100"
                                >
                                    
                                </v-img>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col v-if="lmsListEachService.mango.length > 0">
                                        <div v-for="(lms, i) in lmsListEachService.mango" :key="i">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-tooltip bottom close-delay="2000" color="#333">
                                                        <template v-slot:activator="{ on, attrs }" >
                                                        <v-list-item-title
                                                            v-bind="attrs"
                                                            v-on="on">
                                                            <v-icon>mdi-circle-small</v-icon>
                                                            {{`${lms.title} (${sectionAfterTitleName(lms.sections)})`}}
                                                        </v-list-item-title>
                                                        <v-list-item-title v-if="lms.jobStatus === 'error'">
                                                            <v-icon color="red">mdi-circle-small</v-icon>
                                                            <strong class="red--text text--lighten-1">{{` ${hasObjectError(lms)}`}}</strong>
                                                        </v-list-item-title>
                                                        </template>
                                                        <span >{{sectionAfterTitleName(lms.sections)}}</span>
                                                    </v-tooltip>
                                                </v-list-item-content>
                                                <v-list-item-icon>
                                                    <v-row lign="center"
                                                        justify="center">
                                                        <v-col >
                                                            <template  v-if="currentSemester === courseData.semester">
                                                                <v-row class="align-items-center">
                                                                    <v-col >
                                                                        <v-row>
                                                                            <div class="text-center" style="font-size: 10px;">Auto Add/Drop</div>
                                                                        </v-row>

                                                                        <v-row style="font-size: 10px; margin-left: 0rem;">
                                                                            <div class="text-center bi-state-toggle"  >
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                                                                <button :class="!lms.syncEnable? 'bi-state-toggle-button-no active' : 'bi-state-toggle-button-no'" @click="clickSync(lms.syncEnable, lms.courseid , lms._id )"  id="toggle-button3">
                                                                                </button>
                                                                        
                                                                                <button :class="lms.syncEnable? 'bi-state-toggle-button active' : 'bi-state-toggle-button'" @click="clickSync(lms.syncEnable, lms.courseid , lms._id )" id="toggle-button1">
                                                                                </button>
                                                                            </div>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                            </template>
                                                        </v-col>
                                                        <v-col>
                                                            <v-btn class="mr-1 white--text" width="150px" :color="jobStatusBtn(lms.jobStatus)" @click="showDetailcreating(lms)">
                                                                {{jobStatus(lms.jobStatus)}}
                                                                <i class="fas fa-spinner fa-pulse  fa-spin fa-fw" v-if="lms.jobStatus !== 'created' && lms.jobStatus !== 'error' && lms.jobStatus !== 'synced' && lms.jobStatus !== 'syncing' "></i>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col  v-if="checkAdmin">
                                                            <v-menu
                                                                bottom
                                                                left
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                    icon
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    >
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list>
                                                                    <v-list-item @click="clickEdit(lms)">
                                                                        <v-list-item-title><v-icon>mdi-square-edit-outline</v-icon> Edit</v-list-item-title>
                                                                    </v-list-item> 
                                                                    <v-list-item @click="clickDelete(lms)">
                                                                        <v-list-item-title><v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </v-col>
                                                        <!-- <v-col>
                                                            <a>
                                                                <v-img
                                                                    @click="onClickStudent"
                                                                    class="mt-2" src="../assets/icon/studentList.png">
                                                                </v-img>
                                                            </a>
                                                        </v-col> -->
                                                    </v-row>
                                                </v-list-item-icon>
                                            </v-list-item>
                                            <v-divider v-if="i+1 !== lmsListEachService.mango.length"></v-divider>
                                        </div>
                                    </v-col>
                                    <v-col v-else class="text-center">
                                        <h2>Not found</h2>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-card v-else class="mt-4">
                <v-card-text>
                    <v-row>
                        <v-col class="text-center" style="border-style: dashed;border-radius: 5px;cursor: pointer;" @click="onOpenCreatemodal">
                            <i class="fas fa-plus fa-6x"></i>
                            <h2>Create Mango CMU</h2>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <!-- <course-detail-component /> -->
            <ModalNotify :showModal="showNotifyInfo" :textTitle="textNotifyTitle" :textDetail="textNotifyDetail" :openWeb="openWebNotify" @onClose = "onClose"/>
            <ModalRemoveCourse :showModal="showNotify" :textTitle="textNotifyTitle" :textDetail="textNotifyDetail" :openWeb="openWebNotify" @onClose = "onClose" @onDelete = "onHandleDelete"/>
            <ModalNotifyAutoEnroll :showModal="showNotifyEnroll" :textTitle="textNotifyTitle" :textDetail="textNotifyDetail" @onConfirm="onConfirm" @onClose = "onClose"/>
            <EditCourse :course-data="serviceDetailCourse" :open-modal="openEdit" :course-all-section="courseData" @onClose = "onClose" @modalUpdate="onModalUpdate"></EditCourse>
            <ModalNotifyInfoCreateFlow :showModal="modalCreate" :openWeb="openWeb" :sectionCompleteShow="createComplete" :sectionUncompleteShow="createUncomplete" @onClose="onClose"></ModalNotifyInfoCreateFlow>
        </div>
        <div v-else>
            <v-row justify='space-between'>
                <v-col cols="2">
                </v-col>
                <v-col cols="8">
                    <h1>{{ lmsListEachService.mango[0].title }} Student list</h1>
                    <v-row class="mt-3">
                        <v-expansion-panels v-model="studentPanel" multiple>
                            <v-expansion-panel v-for="(section , idx) in lmsListEachService.mango[0].sections" :key="idx">
                                <v-expansion-panel-header class="violet-outlined">
                                    <h3 style="font-weight: bolder;">{{filterSection(section)}}</h3>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <template>
                                            <v-data-table
                                                :headers="headers"
                                                :items="studentList"
                                                :hide-default-footer="true"
                                                item-value="studentList"
                                                class="elevation-0"
                                            >
                                                <template v-slot:item.no="{item, index}">
                                                    <tr>
                                                        <td class="text-center">{{index + 1}}</td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </template>
                                        </v-col>
                                    
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        
                        </v-expansion-panels>
                    </v-row>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            
        </div>
    </v-container>
    
</template>

<script>
// import CourseDetailComponent from '../components/CourseDetailComponent'
import CreateLmsModal from '../components/CreateLmsModal'
import Swal from 'sweetalert2'
import apiGatewayCpmService from "@/services/apiGatewayService"

import apiLmsManagementService from "@/services/apiLmsManagementService"
import ModalNotify from '../components/ModalNotification/ModalNotify.vue'
import ModalNotifyAutoEnroll from '../components/ModalNotification/ModalNotifyAutoEnroll.vue'
import EditCourse from '../components/CourseManagement/EditCourse.vue'
import ModalRemoveCourse from '../components/ModalRemoveCourse/ModalRemoveCourse.vue'
import ModalNotifyInfoCreateFlow from '../components/ModalNotification/ModalNotifyInfoCreateFlow.vue'
const config = require(`@/config`)
import CryptoJS from 'crypto-js'

export default {
    name: 'CourseLms',
    components: {
    // CourseDetailComponent,
    CreateLmsModal,
    ModalNotify,
    ModalNotifyAutoEnroll, 
    ModalRemoveCourse,
    EditCourse,
    ModalNotifyInfoCreateFlow
},
    data() {
        return {
            panel: [0,1,2],
            studentPanel: [0],
            dialog: null ,
            serviceList:[],
            courseDetail: {},
            lmsList: [],
            lmsListEachService: {
                canvas:[],
                moodle:[],
                mango:[]
            },
            openCreateModal: false,
            intervalStatus: "",
            showMenu: {
                mango: false
            },
            showMango: false,
            sectionSelected:[],
            showNotify : false ,
            showNotifyInfo : false ,
            showNotifyEnroll : false , 
            textNotifyTitle : "",
            textNotifyDetail : "",
            openWebNotify : "" ,
            CourseSync : {
                status : false , 
                courseId : "" , 
                mappingId : "" 
            },
            openEdit : false ,
            serviceDetailCourse : [] , 
            dataDelete : [] , 
            createComplete: [] , 
            createUncomplete : [] ,
            modalCreate : false ,
            checkAdmin : false ,
            emailListAdmin : [
                "athicha.le@cmu.ac.th" , 
                "janejira.kaensarn@cmu.ac.th" , 
                "rapeepan.e@cmu.ac.th" , 
                "chawalid.pun@cmu.ac.th" ,
                "panithi.panwut@cmu.ac.th" , 
                "cmuonline@cmu.ac.th"
            ],
            studentListPage: false,
            itemsPerPage : 5 ,
            headers: [
                {
                    text: 'No',
                    align: 'left',
                    sortable: false,
                    value: 'no',
                },
                { text: 'Student ID', align: 'left', value: 'sis_user_id' },
                { text: 'Name', align: 'left', value: 'name' },
                { text: 'Email', align: 'left', value: 'login_id' },
            ],
            studentList : []
        }
    },
    watch:{
        serviceList(newVal , val){
            console.log("serviceList:>",val , newVal)
            let map = [];
            for(let i=0; i<Object.keys(this.lmsListEachService).length ; i++){
                map.push(Object.keys(this.lmsListEachService)[i])
            }
            this.sectionSelected = []
            for(let i = 0 ; i < newVal.length ; i++){
                for(let j = 0 ; j < this.lmsListEachService[map[newVal[i]]].length ; j++){
                    this.sectionSelected.push(this.lmsListEachService[map[newVal[i]]][j]._id)
                }
            }

        },
        studentPanel(newVal , oldVal){
            console.log("change" , newVal ,oldVal)
        }
      
        
    },
    computed: {
        courseData: {
            get () {
                // console.log("course lms:",this.courseData)
                return this.$store.state.sideBarData
            },
            set () {
                this.$store.commit('setSideBarData')
            }
        },
        userData: {
            get () {
                return this.$store.state.userData
            },
            set () {
                this.$store.commit('setUserData')
            }
        },
        selectAll:{
            get: function () {
              return true
            },
            
            set: function (value) {
                let selected = []
                if (value) {
                    let count = 1;
                    for(let i in this.lmsListEachService){
                        // console.log("mapping:>" , i,this.lmsListEachService[i])
                        if(this.lmsListEachService[i]){
                            selected.push(count)
                            count = count + 1 ;
                        }
                    }
                }
                this.serviceList = selected
                // console.log("check List:>" , selected)
                // this.panel = selected
            }
        },
        currentSemester() {
            return this.$store.state.currentSemester
        },
        nextSemester() {
            return this.$store.state.nextSemester
        },
        yearlySemester() {
            return this.$store.state.yearlySemester
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalStatus)
    },
    created () {
        // this.onClickStudent()
        Swal.fire({
            text: 'loading...',
            allowOutsideClick: false
        })
        Swal.showLoading()
        this.getLmsModule(this.$route.params.courseId, true)

        const bytes = CryptoJS.AES.decrypt(localStorage.getItem("profile"), config.aesKey.key)
        this.userProfile = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        if(this.emailListAdmin.includes(this.userProfile.email)){
            this.checkAdmin = true
        }
    },
    methods: {
        onClickStudent(){
            clearInterval(this.intervalStatus)
            this.studentListPage = true
            this.getListStudents("cmumango" , "10148" , "801")
        },
        async getListStudents(service , serviceId , section){
            let res = await apiGatewayCpmService.getStudentBySection(localStorage.getItem("accessToken")  , service , serviceId , section)
            this.studentList = res[0].data
            console.log("res" , res[0].data)

        },
        async getLmsModule (courseId, closeLoading = false) {
            this.lmsListEachService =  {
                canvas:[],
                moodle:[],
                mango:[]
            }
            const lmsList = await apiGatewayCpmService.getCourseMappingList(courseId,"lms",localStorage.getItem("accessToken"))
            if(lmsList){
                let lms = {
                    canvas:[],
                    moodle:[],
                    mango:[]
                }
                this.lmsList = lmsList
                for(let i=0;i<lmsList.length;i++){
                    switch (lmsList[i].service) {
                        case "canvas":
                         
                            lms.canvas.push(lmsList[i])
                            break
                        case "moodle":
                            
                            lms.moodle.push(lmsList[i])
                            break
                        case "mango":
                           
                            lms.mango.push(lmsList[i])
                            break
                        default:
                            break
                    }
                }
                this.lmsListEachService = lms
                lms = {
                    canvas:[],
                    moodle:[],
                    mango:[]
                }
                clearInterval(this.intervalStatus)
                this.intervalStatus = setInterval(async () => {
                    
                    const lmsList = await apiGatewayCpmService.getCourseMappingList(courseId,"lms",localStorage.getItem("accessToken"))
                    console.log("lmsList:>" , lmsList)
                    for(let i=0;i<lmsList.length;i++){
                        switch (lmsList[i].service) {
                            case "canvas":
                                lms.canvas.push(lmsList[i])
                                break
                            case "moodle":
                               
                                lms.moodle.push(lmsList[i])
                                break
                            case "mango":
                                lms.mango.push(lmsList[i])
                                break
                            default:
                                break
                        }
                    }
                    this.lmsListEachService = lms
                    lms = {
                        canvas:[],
                        moodle:[],
                        mango:[]
                    }
                    // console.log("this.lmsListEachService > :" , this.lmsListEachService)
                }, 5000)
               
            }
            this.showMenu.mango = await this.checkGrant(this.userData.email, `mango`)
            // console.log("check grant:>" , this.showMenu.mango )

            if(closeLoading) Swal.close()
        },
    
        hasObjectError(obj){
            if(obj.errorCode.message){
                return obj.errorCode.message.message
            }else{
                return "NOT FOUND"
            }

        },

        hasOwn(obj, key) {
            console.log("hasobj", hasOwnProperty.call(obj, key))
            return hasOwnProperty.call(obj, key);
        },
        onOpenCreatemodal () {
            this.openCreateModal = true
        },
        async onHandleAutoSync(){
            clearInterval(this.intervalStatus)
            var parsedobj = JSON.parse(JSON.stringify(this.sectionSelected))
            if(parsedobj.length > 0){
                this.dialog = false;
                Swal.fire({
                    icon: "warning",
                    title: "ท่านต้องการเพิ่มลดรายชื่นนักศึกษาให้ตรงกับรายชื่อสำนักทะเบียน" ,
                    text: "",
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if(result.isConfirmed){
                        Swal.fire({
                            text: 'loading...',
                            allowOutsideClick: false
                        })
                        Swal.showLoading()
                        const bytes = CryptoJS.AES.decrypt(localStorage.getItem("profile"), config.aesKey.key)
                        let userProfile = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
                        console.log("userProfile" , userProfile)
                        let formData = {
                            "mode":"forceSyncStudents",
                            "mappingsList":parsedobj,
                            "userEmail": userProfile.email
                        }
                        this.UpdateSyncNow(formData);
                    }else{
                        this.sectionSelected = [];
                        this.serviceList = [];
                    }
                    this.getLmsModule(this.$route.params.courseId, true)
                });
            }else{
                Swal.fire({
                    icon: "warning",
                    title: "ท่านไม่ได้เลือก Section สำหรับการซิงค์" ,
                    text: "",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Cancel'
                });

            }
           

            
        },
        async UpdateSyncNow(formData){
           const response = await apiGatewayCpmService.apiPostJobs(localStorage.getItem("accessToken")  ,formData);
           if(response.status){
                Swal.fire({
                    icon: 'success',
                    title: 'ระบบกำลังซิงค์นักศึกษา',
                    reverseButtons: true
                })
                return response;
            }else{
                this.alertError(response.message)
                return false;
            }
        },
        sectionAfterTitleName (sectionArr) {
            for(let i=0;i<sectionArr.length;i++){
                sectionArr[i] = this.filterSection(sectionArr[i])
            }
            return sectionArr
        },
        filterSection (section) {
            // console.log("filter section:",section)
            let sectionLec = section.substring(0, 3)
            let sectionLab = section.substring(3)
            if (sectionLec === '000' && sectionLab !== '000') {
            return `Lab.${sectionLab}`
            }else if(sectionLec !== '000' && sectionLab === '000'){
            return `Lec.${sectionLec}`
            }
            return section
        },
        async onModalSubmit (val  , typeCreate ) {

            const createLms = await this.selectServiceCreate( typeCreate , this.courseData.semester , val)
            this.createComplete = createLms.createComplete
            this.createUncomplete = createLms.createUncomplete
            this.modalCreate = true
            this.openWeb = `/courses/${this.$route.params.courseId}/lms`
            Swal.close()
           
        },
        mapTermCreate(termSelected){
          console.log("termSelected :>" , termSelected)
          let timePack = {}
          switch(termSelected){
            case config.lmsConfig.currentSemester:
              timePack = {
                "termDateStart" : config.lmsConfig.moodle.termStart,
                "termDateEnd": config.lmsConfig.moodle.termEnd,
                "termId": config.lmsConfig.mango.termId
              }
              return timePack;
            case config.lmsConfig.nextSemester:
              timePack = {
                "termDateStart" : config.lmsConfig.moodleNext.termStart,
                "termDateEnd": config.lmsConfig.moodleNext.termEnd,
                "termId": config.lmsConfig.mangoNext.termId
              }
              return timePack;
            case config.lmsConfig.yearlySemester:
              timePack = {
                "termDateStart" : config.lmsConfig.moodleYearly.termStart,
                "termDateEnd": config.lmsConfig.moodleYearly.termEnd,
                "termId": config.lmsConfig.mangoYearly.termId
              }
              return timePack;
            default:
              timePack = {
                "termDateStart" : config.lmsConfig.moodle.termStart,
                "termDateEnd": config.lmsConfig.moodle.termEnd,
                "termId": config.lmsConfig.mango.termId
              }
              return timePack;
          }
        },
        async selectServiceCreate(typeCreate , termSelected , val){
          let formData = {};
          let createLms = null
          let _createComplete = []
          let _createUncomplete = []

          const termCreate = await this.mapTermCreate(termSelected)
          switch(typeCreate.service){
            case "mango" :
              formData ={
                  "courseId": val.courseId,
                  "termId": termCreate.termId,
                  "sections": val.section
              }
              Swal.fire({
                  text: 'loading...',
                  allowOutsideClick: false
                })
              Swal.showLoading()
              if(typeCreate.option === "sumAll" || val.section.length === 1){
                createLms = await apiGatewayCpmService.CreateLmsMango(localStorage.getItem("accessToken") , formData);
                if(createLms?.status){
                  _createComplete.push(val.section)
                }else{
                  _createUncomplete.push({"section" : val.section , "error" : String(createLms?.message)})
                }
                console.log("createUncomplete" , _createUncomplete)

              }else if(typeCreate.option === "splitAll" && val.section.length !== 1){
                val.section = val.section.sort()
                for(let i = 0 ; i < val.section.length ; i++){
                  let formData ={
                    "courseId": val.courseId,
                    "termId": termCreate.termId,
                    "sections": [val.section[i]]
                  }
                  createLms = await apiGatewayCpmService.CreateLmsMango(localStorage.getItem("accessToken") , formData);
                  if(createLms?.status){
                    _createComplete.push(val.section[i])
                  }else{
                    _createUncomplete.push({"section" : val.section[i] , "error" : String(createLms?.message)})
                  }
                }
              }
              return {
                    'status' : createLms , 
                    'createComplete' : _createComplete ,
                    'createUncomplete' : _createUncomplete
                };
            case "moodle" :
              formData ={
                  "courseId": val.courseId,
                  "termDateStart": termCreate.termDateStart,
                  "termDateEnd": termCreate.termDateEnd,
                  "sections": val.section
              }
              Swal.fire({
                text: 'loading...',
                allowOutsideClick: false
              })
              Swal.showLoading()
              if(typeCreate.option === "sumAll" && val.section.length !== 1){
                createLms = await apiGatewayCpmService.CreateLmsMoodle(localStorage.getItem("accessToken") , formData);
                if(createLms?.status){
                  _createComplete.push(val.section)
                }else{
                  _createUncomplete.push({"section" : val.section , "error" : String(createLms?.message)})

                }
              }else if(typeCreate.option === "splitAll" || val.section.length === 1){
                val.section = val.section.sort()
                for(let i = 0 ; i < val.section.length ; i++){
                  let formData ={
                    "courseId": val.courseId,
                    "termDateStart": termCreate.termDateStart,
                    "termDateEnd": termCreate.termDateEnd,
                    "sections": [String(val.section[i])]
                  }
                  createLms = await apiGatewayCpmService.CreateLmsMoodle(localStorage.getItem("accessToken") , formData);
                  if(createLms?.status){
                    _createComplete.push(val.section[i])
                  }else{
                    _createUncomplete.push({"section" : [val.section[i]] , "error" : String(createLms?.message)})
                  }
                }
              }
              return {
                    'status' : createLms , 
                    'createComplete' : _createComplete ,
                    'createUncomplete' : _createUncomplete
                };
            default:
              break;
            }
        },
        async onModalUpdate( val ){
            console.log("connect api update" , val)

        },
        alertError (errorMessage) {
            let text = ""
            switch (errorMessage) {
                case "mappingHasReadyExit":
                    text = "Course already exist"
                    break
                case "notHavePermission":
                    text = "Don't have permission for mango"
                    break
                default:
                    text = "error"
                    break
            }
            Swal.fire({
                icon: 'error',
                title: 'Create fail',
                text: `${text}`,
            })
        },
       
        async updateWebUrl (mappingId, url) {
            const reqBody = {
                "webUrl": url
            }
            const resUpdateWebUrl = await apiGatewayCpmService.updateMapping(this.courseData._id, mappingId, reqBody, localStorage.getItem("accessToken"))
            return resUpdateWebUrl
        },
        jobStatus (status) {
            switch (status) {
                case 'new': return 'enrolling'
                case 'creating' : return 'enrolling' 
                case 'error': return 'error'
                case 'created': return 'go to mango'
                case 'synced':  return 'go to mango'
                case 'syncing':  return 'go to mango'
                default: return 'not found'
            }
        },
        checkSyncStatus(){

        },
        jobStatusBtn (status) {
            switch (status) {
                case "created":
                    return "#745CA8"
                case "error":
                    return "red"
                default:
                    return "#745CA8"
            }
        },
        showDetailcreating (createStatus) {
            switch (createStatus.jobStatus) {
                case "created":
                    console.log("go to teams")
                    // this.$router.push(`${createStatus.webUrl}`)
                    window.open(`${createStatus.webUrl}`,'_blank')
                    break
                case "synced":
                    console.log("go to teams")
                    // this.$router.push(`${createStatus.webUrl}`)
                    window.open(`${createStatus.webUrl}`,'_blank')
                    break
                case "syncing":
                    console.log("go to teams")
                    // this.$router.push(`${createStatus.webUrl}`)
                    window.open(`${createStatus.webUrl}`,'_blank')
                    break
                case "error":
                    Swal.fire({
                        icon: 'error',
                        title: 'ระบบเกิดข้อพลาด',
                        html: 'เกิดข้อผิดพลาดในการสร้าง microsoft teams <br>กรุณาติดต่อเจ้าหน้าที่',
                        showCloseButton: true,
                        confirmButtonText: '<i class="fas fa-comments"></i> ติดต่อเจ้าหน้าที่',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.open(`https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1`,'_blank')
                        }
                    })
                    break
                default:
                    Swal.fire({
                        icon: 'info',
                        title: 'กำลังดำเนินการสร้าง',
                        text: 'ระบบอาจใช้เวลาสักครู่ในการประมวลผล'
                    })
            }
        },
        clickDelete (data) {
            this.showNotify = true ;
            this.textNotifyTitle = `ยืนยันการลบ "LMS" กลุ่มนี้`;
            this.textNotifyDetail = "" ;
            this.dataDelete =  data;
        },
        onClose(){
            // console.log("check close")
            this.openEdit = false ;
            this.showNotify = false ;
            this.showNotifyInfo = false;
            this.showNotifyEnroll = false ; 
            this.textNotifyTitle = "";
            this.textNotifyDetail = "";
            this.openWebNotify = "" ;
            this.modalCreate = false
        },
        clickSync (status , courseId , mappingId) {
            console.log("clickSync" , status , courseId , mappingId)
            clearInterval(this.intervalStatus)

            this.CourseSync.status = status ;
            this.CourseSync.courseId = courseId ;  
            this.CourseSync.mappingId = mappingId ; 

            this.showNotifyEnroll = true ;
            this.textNotifyTitle = status  ? "ปิดการนำเข้ารายชื่ออัตโนมัติ" : "เปิดการนำเข้ารายชื่ออัตโนมัติ";
            this.textNotifyDetail = `บริการนี้จะทำการเพิ่มรายชื่อนักศึกษาเข้าเท่านั้น หากต้องการให้รายชื่อนักศึกษาตรงกับสำนักทะเบียน กรุณากดปุ่ม "Sync with reg"`;


           
        },
        async onConfirm (){
            console.log("onConfirm" ,this.CourseSync.status)
            let formData = {
                    "syncEnable" : true
            };
            if(this.CourseSync.status){
                formData ={
                    "syncEnable" : false
                }
            }

            let tmp = apiGatewayCpmService.putCourseMappingSync(this.CourseSync.courseId,this.CourseSync.mappingId,localStorage.getItem("accessToken") , formData);
            if(tmp){
                Swal.fire({
                    text: 'loading...',
                    allowOutsideClick: false
                })
                Swal.showLoading()
                this.getLmsModule(this.$route.params.courseId, true)
                this.onClose()
            }
        },
        clickEdit ( lms ) {
            
            this.openEdit = true
            this.serviceDetailCourse = lms
            console.log("check edit" , this.serviceDetailCourse , this.courseData)
           

        },
        onDelete(val){
            console.log("delete api :>" , val)

        },
        clickInfoToggle(){
            console.log("info")
            this.showNotifyInfo = true ; 
            this.textNotifyTitle = "Sync with REG";
            this.textNotifyDetail = "คือ การทำให้รายชื่อนักศึกษาตรงกับที่มีในสำนักทะเบียน \n สามารถทำการได้ตั้งแต่เวลา 8.00-22.00 น." ;
            this.openWebNotify = "https://www.info.tlic.cmu.ac.th/contact-us" ;
            
        },
        async checkGrant(email,grantOn){
            let grantList = await apiLmsManagementService.checkGrantStatus(email)
            if(grantList){
                // console.log("grantList(API):",grantList)
                for(let i=0;i<grantList.length;i++){
                    if(grantList[i].grant_on === grantOn){
                        return true
                    }
                }
            }
            return false
        },
        async onHandleDelete(){
            Swal.fire({
                    text: 'loading...',
                    allowOutsideClick: false
                })
            Swal.showLoading()
            // console.log("del:>", this.dataDelete)
            const res = await apiGatewayCpmService.deleteMappingCourse( localStorage.getItem("accessToken") , this.dataDelete.courseid , this.dataDelete._id)
            if(res.status){
                Swal.fire({
                    icon: 'success',
                    title: 'ทำการลบสำเร็จ',
                })
                this.onClose()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'ทำการลบไม่สำเร็จ',
                })
                this.onClose()
            }
        },
    },
}
</script>
